import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

const TopMenu = () => {
  return (
<nav class="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
	<div class="container-fluid">
		<a class="navbar-brand" href="https://product.blogaihub.com/">프로덕트</a>
		<button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
			<span class="navbar-toggler-icon"></span>
		</button>
		<div class="collapse navbar-collapse" id="navbarCollapse">
			<ul class="navbar-nav me-auto mb-2 mb-md-0">
				<li class="nav-item dropdown">
				  <a class="nav-link dropdown-toggle " href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">계산기</a>
				  <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
					<li><a class="dropdown-item" href="https://calculator1.hunihub.link/">공학용 계산기</a></li>
					<li><a class="dropdown-item" href="https://calculator2.hunihub.link/">간단 계산기</a></li>
					<li><a class="dropdown-item" href="https://bmicalculator.hunihub.link/">BMI 계산기</a></li>
                    <li><a class="dropdown-item" href="https://celebration-day-calculator.hunihub.link/">기념일 디데이 계산기</a></li>
                    <li><a class="dropdown-item" href="https://lunar-solar-calculator.hunihub.link/">양력 음력 계산기</a></li>                    
				 </ul>
				</li>
				<li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle " href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">생산성</a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                      <li><a class="dropdown-item" href="https://todolist1.hunihub.link/">오늘 할일1</a></li>
                      <li><a class="dropdown-item" href="https://react-todo-palette.hunihub.link/">오늘 할일2</a></li>
                    </ul>
                </li>
				<li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle " href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">기타</a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                      <li><a class="dropdown-item" href="https://mbti1.hunihub.link/">MBTI TEST</a></li>
                      <li><a class="dropdown-item" href="http://weatherforecast.hunihub.link/">날씨예보</a></li>
                    </ul>
                </li>
			</ul>
		</div>
	</div>
</nav>
  );
};

export default TopMenu;