import React, { useState, useRef } from "react";
import { Form, Button, Container, Row, Col, Alert } from "react-bootstrap";

const WordRelay = () => {
  const [word, setWord] = useState("제로초");
  const [value, setValue] = useState("");
  const [result, setResult] = useState("");
  const inputRef = useRef(null);

  const onSubmit = (e) => {
    e.preventDefault();

    if (value.length < 2) {
      setResult("두 글자 이상 입력하세요.");
      return;
    }

    let valFirst = value.charAt(0);
    let wordLast = word.charAt(word.length - 1);

    if (valFirst === wordLast) {
      setWord(value);
      setValue("");
      setResult("정답입니다.");
    } else {
      setValue("");
      setResult("틀렸습니다. 다시 입력하세요.");
    }
  };

  const onChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <Container>
      <Row>
        <Col>
          <header class="entry-header" aria-label="Content">
  				<h2 class="entry-title" itemprop="headline">끝말잇기(WordRelay) 게임</h2>
          </header> 
          <div style={{ marginTop: "20px" }}>
            <h3>현재 단어: <b>{word}</b></h3>
          </div>
          <Form onSubmit={onSubmit}>
            <Form.Group controlId="wordInput">
              <Form.Control
                type="text"
                onChange={onChange}
                value={value}
                ref={inputRef}
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              입력!
            </Button>
          </Form>
          {result && <Alert variant={result.includes("틀렸습니다.") ? "danger" : "success"}>{result}</Alert>}
        </Col>
      </Row>
    </Container>
  );
};

export default WordRelay;
