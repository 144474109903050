import React from 'react';
import WordRelay from "./component/WordRelay";
import TopMenu from './TopMenu';

function App() {
  return (
    <>
      <TopMenu />
      <br />
      <WordRelay />
    </>
  );
}

export default App;
